<template>
  <div>
    <Navigation/>
    <!-- 律师函 -->
    <div class="bg_box">
      <div class="noneBox"></div>
      <div class="box">
        <p class="back" @click="$router.push('/aiNav')">返回</p>
        <div class="ct_box flex">
          <div class="form_upload_box">
            <ai_title ai_title="“AI助手”-律师函"/>
            <div class="form_ct flex">
              <p class="upLoad_text">被告公司或个人姓名：</p>
              <div class="upload_box">
                <el-input v-model="form.defendant_name" placeholder="请输入被告公司或个人姓名"></el-input>

              </div>
            </div>
            <div class="form_ct flex">
              <p class="upLoad_text">委托人姓名：</p>
              <div class="upload_box">
                <el-input v-model="form.plaintiff_name" placeholder="请输入委托人姓名"></el-input>
              </div>
            </div>
            <div class="form_ct flex">
              <p class="upLoad_text">委托人性别：</p>
              <div class="upload_box flex">
                <el-radio v-model="form.plaintiff_sex" label="男">男</el-radio>
                <el-radio v-model="form.plaintiff_sex" label="女">女</el-radio>

              </div>
            </div>
            <div class="form_ct flex">
              <p class="upLoad_text">委托人身份证号：</p>
              <div class="upload_box">
                <el-input v-model="form.plaintiff_id" placeholder="请输入委托人身份证号"></el-input>
              </div>
            </div>
            <div class="form_ct flex">
              <p class="upLoad_text">委托人联系电话：</p>
              <div class="upload_box">
                <el-input v-model="form.plaintiff_mobile" placeholder="请输入委托人联系电话"></el-input>
              </div>
            </div>
            <div class="form_ct flex">
              <p class="upLoad_text">委托人联系地址：</p>
              <div class="upload_box">
                <el-input v-model="form.plaintiff_address" placeholder="请输入委托人联系地址"></el-input>
              </div>
            </div>
            <div class="form_ct flex">
              <p class="upLoad_text">事实阐述：</p>
              <div class="upload_box">
                <el-input :autosize="{ minRows: 4, maxRows: 4 }" class="upload_textarea" type="textarea"
                          resize="none" v-model="form.plaintiff_fact"
                          placeholder="详细阐述事实依据，包括涉及人物的行为、未履行的义务、对委托人造成的影响等，确保事实清楚证据确凿"></el-input>
              </div>
            </div>
            <div class="form_ct flex">
              <p class="upLoad_text">我方诉求：</p>
              <div class="upload_box">
                <el-input resize="none" :autosize="{ minRows: 4, maxRows: 4 }" class="upload_textarea"
                          type="textarea" v-model="form.plaintiff_appeal"
                          placeholder="具体的要求事项，如停止侵权行为、赔偿损失等"></el-input>
              </div>
            </div>
            <ai_button_file_data ref="ai_button_file_data" @file_data="file_data"/>
            <ai_text_content :contentData="contentData"/>
            <aiNum title="计费规则：1000tokens = 0.9元，" text="1000tokens在700-750字左右" :visible="aiNumShow"
                   @close="aiNumShow = false" @postData="postData"/>
            <aiPoup :visible="aiPoupShow" @close="aiPoupShow = false"/>
          </div>
          <word-nav/>

        </div>

      </div>


    </div>
    <footerBot/>
  </div>
</template>

<script>
import wordNav from '@/components/wordNav.vue'
import ai_text_content from '@/components/ai_text_content'
import aiNav from '@/components/aiNav'
import ai_title from '@/components/ai_title'
import ai_button_file_data from '@/components/ai_button_file_data'

export default {
  mounted() {
    this.getUserInfo()
  },
  components: {
    aiNav,
    ai_text_content,
    ai_title,
    ai_button_file_data,
    wordNav
  },
  data() {
    return {
      form: {
        defendant_name: '', //被告姓名
        plaintiff_name: '', //原告姓名
        plaintiff_sex: '男', // 原告性别
        plaintiff_id: '', //原告身份证
        plaintiff_mobile: '', // 原告手机号
        plaintiff_address: '', //原告地址
        plaintiff_fact: '', //事实阐述
        plaintiff_appeal: '', //原告诉求
      },
      contentData: {}, //内容
      aiNumShow: false,
      aiPoupShow: false,
    }
  },
  methods: {
    getImg(url) {
      this.contentData.origin_url = url
      console.log('contentData', this.contentData);
    },
    // 获取用户信息
    getUserInfo() {
      let $user_info = JSON.parse(localStorage.getItem('user_info'))
      if ($user_info) {
        this.curlGet('/api/user/info').then((res) => {
          if (res.data.code) {
            this.$user_info = res.data.data
            this.$util.setLocalStorage('user_info', res.data.data) // 写入local storage
          }
        })
      }
    },
    file_data() {
      if (this.$user_info.money <= 0) {
        this.aiPoupShow = true
      } else {
        this.aiNumShow = true
      }
    },
    postData() {
      this.aiNumShow = false
      this.$refs.ai_button_file_data.loading_show()
      let data = this.form
      data.mobile = this.$user_info.mobile
      this.curlPost('file/lawyer_create', data).then(res => {
        this.$refs.ai_button_file_data.loading_show()
        if (res.data.code) {
          this.contentData = res.data.data

          // 扣费
          this.curlPost('/api/users/money_log/spend', {
            ai_id: res.data.data.id,
            amount: res.data.data.money,
          }).then(res => {
            if (res.data.code) {
              this.getUserInfo()
            }
          })
        } else {
          this.$message.error(res.data.msg);
        }
      })
    }

  },
}
</script>

<style lang=scss scoped>
@import '@/scss/ai';
</style>